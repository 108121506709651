import React from "react";
import './Footer.css';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useMediaQuery, useTheme } from "@mui/material";
import HireFromIs from "../PopUp/HireFromIs";

export default function AllFooter() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleSocialMedia = (value) => {
        if(value === "youtube"){
            window.open(`https://www.youtube.com/channel/UCmoB9HjrMnB3eZ64qzb5lBg`, "_blank");
        } else if(value === "linkedin"){
            window.open(`https://www.linkedin.com/in/paperlive-learning-9b5669322/`, "_blank");
        } else if(value === "facebook"){
            window.open(`https://www.instagram.com/paperlive_learning/`, "_blank");
        } else if(value === "instagram"){
            window.open(`https://www.instagram.com/paperlive_learning/`, "_blank");
        }
    }

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);

    return (
        <React.Fragment>
            <div class="pg-footer">
                <footer class="footer">
                    <svg class="footer-wave-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 100" preserveAspectRatio="none">
                        <path class="footer-wave-path" d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"></path>
                    </svg>
                    <div class="footer-content">
                        <div class="footer-content-column">
                            {/* <div class="footer-logo">
                                <a class="footer-logo-link" href="#">
                                    <span class="hidden-link-text">LOGO</span> */}
                                    {/* <img src={PaperLiveLogo} alt="paperlive" className="logo" style={{ cursor: "pointer" }} height={"100px"} width={"1000px"} /> */}
                                {/* </a>
                            </div> */}
                            <div class="footer-menu">
                                <h2 class="footer-menu-name">PAPERLIVE LEARNING</h2>
                                <ul id="menu-get-started" class="footer-menu-list">
                                    <li class="menu-item menu-item-type-post_type menu-item-object-product">
                                        <a href="/about-us">About</a>
                                    </li>
                                    <li class="menu-item menu-item-type-post_type menu-item-object-product">
                                        <a href="#reviews">Reviews</a>
                                    </li>
                                    <li class="menu-item menu-item-type-post_type menu-item-object-product">
                                        <a onClick={handleOpen} style={{cursor: "pointer"}}>Hire From Us</a>
                                    </li>
                                    <li class="menu-item menu-item-type-post_type menu-item-object-product">
                                        <a onClick={handleOpen} style={{cursor: "pointer"}}>Become a Trainer</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="footer-content-column">
                            <div class="footer-menu">
                                <h2 class="footer-menu-name"> TESTIMONIALS</h2>
                                <ul id="menu-company" class="footer-menu-list">
                                    <li class="menu-item menu-item-type-post_type menu-item-object-page">
                                        <a href="#testimonialsVideo">Success Stories</a>
                                    </li>
                                    {/* <li class="menu-item menu-item-type-taxonomy menu-item-object-category">
                                        <a href="#">News</a>
                                    </li>
                                    <li class="menu-item menu-item-type-post_type menu-item-object-page">
                                        <a href="#">Careers</a>
                                    </li> */}
                                </ul>
                            </div>
                            <div class="footer-menu">
                                <h2 class="footer-menu-name">SUPPORT</h2>
                                <ul id="menu-legal" class="footer-menu-list">
                                    <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-170434">
                                        <a href="/refund-policy">Refund Policy</a>
                                    </li>
                                    <li class="menu-item menu-item-type-post_type menu-item-object-page">
                                        <a href="/terms">Terms & Conditions</a>
                                    </li>
                                    <li class="menu-item menu-item-type-post_type menu-item-object-page">
                                        <a href="/privacy-policy">Privacy Policy</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="footer-content-column">
                            <div class="footer-menu">
                                <h2 class="footer-menu-name"> RESOURCES</h2>
                                <ul id="menu-quick-links" class="footer-menu-list">
                                    {/* <li class="menu-item menu-item-type-custom menu-item-object-custom">
                                        <a target="_blank" rel="noopener noreferrer" href="#">Support Center</a>
                                    </li>
                                    <li class="menu-item menu-item-type-custom menu-item-object-custom">
                                        <a target="_blank" rel="noopener noreferrer" href="#">Service Status</a>
                                    </li>
                                    <li class="menu-item menu-item-type-post_type menu-item-object-page">
                                        <a href="#">Security</a>
                                    </li> */}
                                    <li class="menu-item menu-item-type-post_type menu-item-object-page">
                                        <a href="/blog">Blog</a>
                                    </li>
                                    {/* <li class="menu-item menu-item-type-post_type_archive menu-item-object-customer">
                                        <a href="#">Customers</a></li> */}
                                    <li class="menu-item menu-item-type-post_type menu-item-object-page">
                                        <a href="#reviews">Reviews</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="footer-menu">
                                <h2 class="footer-menu-name">COURSES</h2>
                                <ul id="menu-quick-links" class="footer-menu-list">
                                    <li class="menu-item menu-item-type-post_type menu-item-object-page">
                                        <a href="/devops-training-online">DevOps and Cloud Job Ready Program</a>
                                    </li>
                                    <li class="menu-item menu-item-type-post_type menu-item-object-page">
                                        <a href="/devops-certification-training">DevOps Certification Training</a>
                                    </li>
                                    <li class="menu-item menu-item-type-post_type menu-item-object-page">
                                        <a href="/aws-solution-architect">AWS Certification Training</a>
                                    </li>
                                    <li class="menu-item menu-item-type-post_type menu-item-object-page">
                                        <a href="/microsoft-azure">Azure Certification Training</a>
                                    </li>
                                    <li class="menu-item menu-item-type-post_type menu-item-object-page">
                                        <a href="/google-cloud-platform">GCP Certification Training</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="footer-content-column">
                            {/* <div class="footer-call-to-action">
                                <h2 class="footer-call-to-action-title"> Let's Chat</h2>
                                <p class="footer-call-to-action-description"> Have a support question?</p>
                                <a class="footer-call-to-action-button button" href="#" target="_self"> Get in Touch </a>
                            </div> */}
                            <div class="footer-call-to-action">
                                <h2 class="footer-call-to-action-title"> CONTACT Us</h2>
                                <p class="footer-call-to-action-link-wrapper"> <a class="footer-call-to-action-link" href="tel:+918114942378" target="_self">East India: +91 8114942378 </a></p>
                                <p class="footer-call-to-action-link-wrapper"> <a class="footer-call-to-action-link" href="tel:+919110995894" target="_self">South India: +91 9110995894 </a></p>
                                <p class="footer-call-to-action-link-wrapper"> <a class="footer-call-to-action-link" href="tel:+918101064998" target="_self">North India: +91 8101064998 </a></p>
                                <p class="footer-call-to-action-link-wrapper"> <a class="footer-call-to-action-link" href="tel:+919430390172" target="_self">West India: +91 9430390172 </a></p>
                            </div>
                        </div>
                        <div class="footer-social-links"> 
                            {/* <FacebookRoundedIcon style={{margin: "0 2rem 0 0", cursor: "pointer"}} onClick={(e) => handleSocialMedia("facebook")}/> */}
                            <InstagramIcon style={{margin: "0 2rem 0 0", cursor: "pointer"}} onClick={(e) => handleSocialMedia("instagram")}/>
                            <LinkedInIcon style={{margin: "0 2rem 0 0", cursor: "pointer"}} onClick={(e) => handleSocialMedia("linkedin")}/>
                            <YouTubeIcon style={{margin: "0 2rem 0 0", cursor: "pointer"}} onClick={(e) => handleSocialMedia("youtube")}/>
                        </div>
                    </div>
                    <div class="footer-copyright">
                        <div class="footer-copyright-wrapper">
                            <p class="footer-copyright-text">
                                <a class="footer-copyright-link" target="_self"> ©2025 | PaperLive | All rights reserved. </a>
                            </p>
                        </div>
                    </div>
                </footer>
            </div>
            {open &&
                <HireFromIs setOpen={setOpen} open={open} isSmallScreen={isSmallScreen} refer={false}/>
            }
        </React.Fragment>
    );
}