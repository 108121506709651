import React, { useEffect, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button } from '@mui/material';
import ScholarshipPopup from '../PopUp/ScholarshipPopup';
const Timer = (props) => {
    const {isSmallScreen} = props;
    const [timeLeft, setTimeLeft] = useState(0);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);

    const calculateTimeLeft = () => {
        const now = new Date();

        // Convert current time to IST (UTC + 5:30)
        const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC +5:30
        const currentISTTime = new Date(now.getTime() + istOffset);

        // Get current date and the next midnight IST (00:00:00)
        const nextReset = new Date(currentISTTime);
        nextReset.setHours(0, 0, 0, 0); // Set to 00:00:00 IST

        // If the current time is past 00:00 AM IST today, set the reset time to tomorrow
        if (currentISTTime.getHours() >= 0 && currentISTTime.getMinutes() > 0) {
            nextReset.setDate(nextReset.getDate() + 1);
        }

        // Calculate the difference between the current time and the next 00:00 IST
        const diff = nextReset - currentISTTime;
        return Math.floor(diff / 1000); // Return difference in seconds
    };

    useEffect(() => {
        // Initialize the timer with the calculated time left
        setTimeLeft(calculateTimeLeft());

        // Update the countdown every second
        const intervalId = setInterval(() => {
            setTimeLeft((prevTimeLeft) => {
                if (prevTimeLeft <= 1) {
                    return calculateTimeLeft(); // Recalculate time for the next 00:00 IST
                }
                return prevTimeLeft - 1;
            });
        }, 1000);

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    // Function to format time into Days, HH:MM:SS
    const formatTime = (seconds, event) => {
        const days = Math.floor(seconds / (3600 * 24)); // Calculate total days
        const hrs = Math.floor((seconds % (3600 * 24)) / 3600); // Remaining hours
        const mins = Math.floor((seconds % 3600) / 60); // Remaining minutes
        const secs = seconds % 60; // Remaining seconds
        if (event === "secs") {
            return Math.abs(secs.toString().padStart(2, '0'));
        } else if (event === "mins") {
            return Math.abs(mins.toString().padStart(2, '0'));
        } else if (event === "hrs") {
            return Math.abs(hrs.toString().padStart(2, '0'));
        } else if (event === "days") {
            return Math.abs(days.toString().padStart(2, '0'));
        } else {
            return `${days}d ${hrs.toString().padStart(2, '0')}h:${mins.toString().padStart(2, '0')}m:${secs.toString().padStart(2, '0')}s`;
        }
    };

    return (
        <div style={{ display:!isSmallScreen && "flex", backgroundColor: "#0e6cac" }}>
            <div style={{margin: "auto", display: "flex", width: isSmallScreen && "10rem", padding: isSmallScreen&& "0.5rem 0 0.5rem 0"}}>
                <div style={{ margin:isSmallScreen? "auto" : "0.5rem", padding: "0 0.2rem 0 0.2rem ", backgroundColor: "white", width: "1.5rem" }}>
                    <div style={{ fontSize:isSmallScreen? "1rem": "1.3rem", textAlign: "center", fontWeight: "700" }}>
                        {formatTime(timeLeft, "days")}
                    </div>
                    <div style={{ textAlign: "center", fontSize: "0.6rem" }}>
                        DAY
                    </div>
                </div>
                <div style={{ margin:isSmallScreen? "auto" : "0.5rem", padding: "0 0.2rem 0 0.2rem ", backgroundColor: "white" , width: "1.5rem" }}>
                    <div style={{ fontSize:isSmallScreen? "1rem": "1.3rem", textAlign: "center", fontWeight: "700" }}>
                        {formatTime(timeLeft, "hrs")}
                    </div>
                    <div style={{ textAlign: "center", fontSize: "0.6rem" }}>
                        HR
                    </div>
                </div>
                <div style={{ margin:isSmallScreen? "auto" : "0.5rem", padding: "0 0.2rem 0 0.2rem ", backgroundColor: "white" ,  width: "1.5rem" }}>
                    <div style={{ fontSize:isSmallScreen? "1rem": "1.3rem", textAlign: "center", fontWeight: "700" }}>
                        {formatTime(timeLeft, "mins")}
                    </div>
                    <div style={{ textAlign: "center", fontSize: "0.6rem" }}>
                        MIN
                    </div>
                </div>
                <div style={{ margin:isSmallScreen? "auto" : "0.5rem", padding: "0 0.2rem 0 0.2rem ", backgroundColor: "white",  width: "1.5rem"  }}>
                    <div style={{ fontSize:isSmallScreen? "1rem": "1.3rem", textAlign: "center", fontWeight: "700" }}>
                        {formatTime(timeLeft, "secs")}
                    </div>
                    <div style={{ textAlign: "center", fontSize: "0.6rem" }}>
                        SEC
                    </div>
                </div>
            </div>
            <div style={{ fontSize: isSmallScreen ? "1.2rem" : "1.5rem", margin: "auto", fontWeight: "600", color: "white" , textAlign: "center"}}>
            Get Scholarship Upto <span style={{color: "yellow", fontSize: isSmallScreen ? "1.4rem" : "1.8rem", }}>35%</span> off
            </div>
            <div style={{ margin :"auto", fontWeight: "600", textAlign: "center" }}>
                <Button
                onClick={handleOpen}
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<ContentCopyIcon />}
                    style={{fontSize: isSmallScreen ? "0.8rem" : "0.8rem",backgroundColor: "white", color: "black", borderRadius: "25px", fontWeight: "600", margin:isSmallScreen && "10px auto 10px auto"}}
                >
                    Check Eligibility
                </Button>
            </div>
            {open &&
                <ScholarshipPopup setOpen={setOpen} open={open} isSmallScreen={isSmallScreen} refer={false}/>
            }
        </div>
    );
};

export default Timer;
