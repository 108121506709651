import { useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import HeaderDrawer from './HeaderDrawer';
import CourseDropdown from './CourseDropdown';
import PaperLiveLogo from './paperlive-new-logo.webp';


const AllHeader = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleHomePage = () => {
    window.open(`/`, "_self");
  }

  return (
    <header className="header">
      <img loading="lazy" src={PaperLiveLogo} alt="paperlive" className="logo" onClick={handleHomePage} style={{ cursor: "pointer" }} />
      {isSmallScreen
        ?
        <div style={{display:"flex"}}>
          <CourseDropdown />
          <HeaderDrawer />
        </div>
        :
        <nav>
          <ul className="nav-links">
            <li style={{ fontSize: "1.125rem", marginRight: "0rem" }}><CourseDropdown /></li>
            <li style={{ fontSize: "1.125rem", margin: "auto", fontWeight: 600 }}><a href="/blog">Blog</a></li>
            <li style={{ fontSize: "1.125rem", margin: "auto", fontWeight: 600 }}><a href="#referAndEarn">Invite and Earn</a></li>
            {/* <li style={{fontSize: "1.125rem"}}><a href="#free-masterclass">Free Masterclass</a></li> */}
            {/* <li><a href="#more">More</a></li> */}
          </ul>
        </nav>
      }
      {/* <button className="sign-up-button">Sign Up</button> */}
    </header>
  );
}

export default AllHeader;
