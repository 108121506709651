import React, { useState, Suspense, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useMediaQuery, useTheme } from "@mui/material";
import { Helmet } from "react-helmet";
import { DotLoader } from "react-spinners";
import Timer from "../components/Timer";
import ExitPagePopup from "../PopUp/ExitPagePopup";
import AllHeader from "../Header/AllHeader";
import StickyFooter from "../Footer/StickyFooter";
import AllFooter from "../Footer/AllFooter";

const Stats = React.lazy(() => import("../components/Stats"));
const ReviewsTab = React.lazy(() => import("../components/ReviewsTab"));
const CompanyBasedStats = React.lazy(() => import("../components/CompanyBasedStats"));
const TrainingFee = React.lazy(() => import("../components/TrainingFee"));
const IncludedInCourse = React.lazy(() => import("../components/IncludedInCourse"));
const CourseModules = React.lazy(() => import("../components/CourseModules"));
const ToolsCovered = React.lazy(() => import("../components/ToolsCovered"));
const TestimonialsVideo = React.lazy(() => import("../components/TestimonialsVideo"));
const UpcomingBatches = React.lazy(() => import("../components/UpcomingBatches"));
const CompanyLogos = React.lazy(() => import("../components/CompanyLogos"));
const ProjectDetails = React.lazy(() => import("../components/ProjectDetails"));
const CareerAssistance = React.lazy(() => import("../components/CareerAssistance"));
const Certificates = React.lazy(() => import("../components/Certificates"));
const MediaCardNew = React.lazy(() => import("../components/MediaCardNew"));
const GetInTouch = React.lazy(() => import("../components/GetInTouch"));
const FAQs = React.lazy(() => import("../FAQs/FAQs"));
const Awards = React.lazy(() => import("../LandingPageComponents/Awards"));
const ContactUsPopUp = React.lazy(() => import("../components/ContactUsPopUp"));
const ReferAndEarn = React.lazy(() => import("../components/ReferAndEarn"));
const WhatsappCommunity = React.lazy(() => import("../components/WhatsappCommunity"));
const LPJourney = React.lazy(() => import("../LandingPageComponents/LPJourney"));
const MutliCloud = React.lazy(() => import("../components/MutliCloud"));
const BarGraph = React.lazy(() => import("../components/BarGraph"));
const Roadmap = React.lazy(() => import("../components/Roadmap"));
const Prerequisites = React.lazy(() => import("../components/Prerequisites"));
const FinishTheCourse = React.lazy(() => import("../components/FinishTheCourse"));
const Instructors = React.lazy(() => import("../components/Instructors"));
const HelpYou = React.lazy(() => import("../components/HelpYou"));
const DevopsArchitect = React.lazy(() => import("../components/DevopsArchitect"));
const IndustryTrend = React.lazy(() => import("../components/IndustryTrend"));
const HR = React.lazy(() => import("../components/HR"));
const NewsRoom = React.lazy(() => import("../components/NewsRoom"));
const GoogleReviews = React.lazy(() => import("../components/GoogleReviews"));
const CaseStudy = React.lazy(() => import("../components/CaseStudy"));

export default function DevopsMain(props) {
  const {city} = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [showPopup, setShowPopup] = useState(false);
  const [popupShown, setPopupShown] = useState(false);

  const [refStats, inViewStats] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refIncludedInCourse, inViewIncludedInCourse] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refCourseModules, inViewCourseModules] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refToolsCovered, inViewToolsCovered] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refTestimonialsVideo, inViewTestimonialsVideo] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refReviewsTab, inViewReviewsTab] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refCompanyBasedStats, inViewCompanyBasedStats] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refTrainingFee, inViewTrainingFee] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refUpcomingBatches, inViewUpcomingBatches] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refCompanyLogos, inViewCompanyLogos] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refProjectDetails, inViewProjectDetails] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refCareerAssistance, inViewCareerAssistance] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refCertificates, inViewCertificates] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refAwards, inViewAwards] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refFAQs, inViewFAQs] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refGetInTouch, inViewGetInTouch] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refReferAndEarn, inViewReferAndEarn] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [whatsappCommunity, inViewWhatsappCommunity] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refLPJourney, inViewLPJourney] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refMutliCloud, inViewMutliCloud] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refBarGraph, inViewBarGraph] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refRoadmap, inViewRoadmap] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refPrerequisites, inViewPrerequisites] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refFinishTheCourse, inViewFinishTheCourse] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refInstructors, inViewInstructors] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refHelpYou, inViewHelpYou] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refDevopsArchitect, inViewDevopsArchitect] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refIndustryTrend, inViewIndustryTrend] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refHR, inViewHR] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refNewsRoom, inViewNewsRoom] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refGoogleReviews, inViewGoogleReviews] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refCaseStudy, inViewCaseStudy] = useInView({ triggerOnce: true, threshold: 0.1 });


  useEffect(() => {
    const handleMouseLeave = (event) => {
      if ((event.clientY <= 0 || event.clientX <= 0 || event.clientX > window.innerWidth || event.clientY > window.innerHeight) &&
        !popupShown) {
        setShowPopup(true);
        setPopupShown(true);
      }
    };

    window.addEventListener("mouseout", handleMouseLeave);

    return () => {
      window.removeEventListener("mouseout", handleMouseLeave);
    };
  }, [popupShown]);

  return (
    <React.Fragment>
      {/* <Helmet>
        <title>DevOps and Cloud Job Ready Program | PaperLive Learning</title>
        <meta name="description" content="Master DevOps with hands-on training! With real-time projects for practical experience. Train with industry experts, work on live projects, and gain the skills top companies demand." />
      </Helmet> */}
      <AllHeader />
      <StickyFooter />
      <Timer isSmallScreen={isSmallScreen} />
      <Suspense fallback={<div style={{ margin: "50% auto auto auto", display: "table" }}><DotLoader color="#8f128e" loading /></div>}>
        {
          <MediaCardNew isSmallScreen={isSmallScreen} heading1={"Devops Certification Training"} heading2={"Job Ready Program"} description={"Advance your career with our comprehensive DevOps Certification Training! Tailored for ambitious professionals, this intensive program provides a perfect blend of in-depth hands-on training and real-world projects to solidify your skills in the most sought-after DevOps technologies. Whether you're a beginner or looking to upskill your technical expertise, our expert-led DevOps Training Program will equip you with the practical knowledge and tools needed to excel. Enroll in our DevOps Certification Training today and gain a competitive edge in the fast-paced world of DevOps Computing!"}/>
        }
      </Suspense>

      <div ref={refStats}>
        {inViewStats && (
          <Suspense fallback={<div style={{ margin: "50% auto auto auto", display: "table" }}><DotLoader color="#8f128e" loading /></div>}>
            <Stats isSmallScreen={isSmallScreen} heading={"DevopsCertification"}/>
          </Suspense>
        )}
      </div>

      <div ref={refBarGraph}>
        {inViewBarGraph && (
          <Suspense fallback={<div style={{ margin: "50% auto auto auto", display: "table" }}><DotLoader color="#8f128e" loading /></div>}>
            <BarGraph isSmallScreen={isSmallScreen} heading={"DevopsCertification"}/>
          </Suspense>
        )}
      </div>

      <div ref={refIncludedInCourse}>
        {inViewIncludedInCourse && (
          <Suspense fallback={<div style={{ margin: "50% auto auto auto", display: "table" }}><DotLoader color="#8f128e" loading /></div>}>
            <IncludedInCourse isSmallScreen={isSmallScreen} heading={"DevopsCertification"}/>
          </Suspense>
        )}
      </div>

      {/* <div ref={refMutliCloud}>
        {inViewMutliCloud && (
          <Suspense fallback={<div style={{ margin: "50% auto auto auto", display: "table" }}><DotLoader color="#8f128e" loading /></div>}>
            <MutliCloud isSmallScreen={isSmallScreen} heading={"DevopsCertification"}/>
          </Suspense>
        )}
      </div> */}

      <div ref={refCourseModules}>
        {inViewCourseModules && (
          <Suspense>
            <CourseModules isSmallScreen={isSmallScreen} heading={"DevopsCertification"}/>
          </Suspense>
        )}
      </div>

      <div ref={refHelpYou}>
        {inViewHelpYou && (
          <Suspense>
            <HelpYou isSmallScreen={isSmallScreen} />
          </Suspense>
        )}
      </div>

      <div ref={refToolsCovered}>
        {inViewToolsCovered && (
          <Suspense fallback={<div style={{ margin: "50% auto auto auto", display: "table" }}><DotLoader color="#8f128e" loading /></div>}>
            <ToolsCovered isSmallScreen={isSmallScreen} heading={"DevopsCertification"}/>
          </Suspense>
        )}
      </div>

      <div ref={refTestimonialsVideo}>
        {inViewTestimonialsVideo && (
          <Suspense fallback={<div style={{ margin: "50% auto auto auto", display: "table" }}><DotLoader color="#8f128e" loading /></div>}>
            <TestimonialsVideo isSmallScreen={isSmallScreen} />
          </Suspense>
        )}
      </div>

      <div ref={refReviewsTab}>
        {inViewReviewsTab && (
          <Suspense fallback={<div style={{ margin: "50% auto auto auto", display: "table" }}><DotLoader color="#8f128e" loading /></div>}>
            <ReviewsTab isSmallScreen={isSmallScreen} />
          </Suspense>
        )}
      </div>

      <div ref={refCompanyBasedStats}>
        {inViewCompanyBasedStats && (
          <Suspense fallback={<div style={{ margin: "50% auto auto auto", display: "table" }}><DotLoader color="#8f128e" loading /></div>}>
            <CompanyBasedStats isSmallScreen={isSmallScreen} />
          </Suspense>
        )}
      </div>

      <div ref={refTrainingFee}>
        {inViewTrainingFee && (
          <Suspense fallback={<div style={{ margin: "50% auto auto auto", display: "table" }}><DotLoader color="#8f128e" loading /></div>}>
            <TrainingFee isSmallScreen={isSmallScreen} heading={"DevopsCertification"}/>
          </Suspense>
        )}
      </div>

      <div ref={refUpcomingBatches}>
        {inViewUpcomingBatches && (
          <Suspense fallback={<div style={{ margin: "50% auto auto auto", display: "table" }}><DotLoader color="#8f128e" loading /></div>}>
            <UpcomingBatches isSmallScreen={isSmallScreen} heading={"DevopsCertification"}/>
          </Suspense>
        )}
      </div>

      <div ref={refInstructors}>
        {inViewInstructors && (
          <Suspense fallback={<div style={{ margin: "50% auto auto auto", display: "table" }}><DotLoader color="#8f128e" loading /></div>}>
            <Instructors isSmallScreen={isSmallScreen} />
          </Suspense>
        )}
      </div>

      {/* <div ref={refRoadmap}>
        {inViewRoadmap && (
          <Suspense fallback={<div style={{ margin: "50% auto auto auto", display: "table" }}><DotLoader color="#8f128e" loading /></div>}>
            <Roadmap isSmallScreen={isSmallScreen} />
          </Suspense>
        )}
      </div> */}

      <div ref={refCompanyLogos}>
        {inViewCompanyLogos && (
          <Suspense>
            <CompanyLogos isSmallScreen={isSmallScreen} />
          </Suspense>
        )}
      </div>

      <div ref={refHR}>
        {inViewHR && (
          <Suspense>
            <HR isSmallScreen={isSmallScreen} />
          </Suspense>
        )}
      </div>

      <div ref={refProjectDetails}>
        {inViewProjectDetails && (
          <Suspense>
            <ProjectDetails isSmallScreen={isSmallScreen} />
          </Suspense>
        )}
      </div>

      <div ref={refCaseStudy}>
        {inViewCaseStudy && (
          <Suspense>
            <CaseStudy isSmallScreen={isSmallScreen} />
          </Suspense>
        )}
      </div>

      <div ref={refPrerequisites}>
        {inViewPrerequisites && (
          <Suspense>
            <Prerequisites isSmallScreen={isSmallScreen} heading={"DevopsCertification"}/>
          </Suspense>
        )}
      </div>

      <div ref={refCareerAssistance}>
        {inViewCareerAssistance && (
          <Suspense fallback={<div style={{ margin: "50% auto auto auto", display: "table" }}><DotLoader color="#8f128e" loading /></div>}>
            <CareerAssistance isSmallScreen={isSmallScreen} />
          </Suspense>
        )}
      </div>

      <div ref={refLPJourney}>
        {inViewLPJourney && (
          <Suspense fallback={<div style={{ margin: "50% auto auto auto", display: "table" }}><DotLoader color="#8f128e" loading /></div>}>
            <LPJourney isSmallScreen={isSmallScreen} />
          </Suspense>
        )}
      </div>

      <div ref={whatsappCommunity}>
        {inViewWhatsappCommunity && (
          <Suspense fallback={<div style={{ margin: "50% auto auto auto", display: "table" }}><DotLoader color="#8f128e" loading /></div>}>
            <WhatsappCommunity isSmallScreen={isSmallScreen} />
          </Suspense>
        )}
      </div>

      <div ref={refCertificates}>
        {inViewCertificates && (
          <Suspense fallback={<div style={{ margin: "50% auto auto auto", display: "table" }}><DotLoader color="#8f128e" loading /></div>}>
            <Certificates isSmallScreen={isSmallScreen} heading={"DevopsCertification"}/>
          </Suspense>
        )}
      </div>

      <div ref={refFinishTheCourse}>
        {inViewFinishTheCourse && (
          <Suspense fallback={<div style={{ margin: "50% auto auto auto", display: "table" }}><DotLoader color="#8f128e" loading /></div>}>
            <FinishTheCourse isSmallScreen={isSmallScreen} />
          </Suspense>
        )}
      </div>

      <div ref={refAwards}>
        {inViewAwards && (
          <Suspense fallback={<div style={{ margin: "50% auto auto auto", display: "table" }}><DotLoader color="#8f128e" loading /></div>}>
            <Awards />
          </Suspense>
        )}
      </div>

      <div ref={refReferAndEarn}>
        {inViewReferAndEarn && (
          <Suspense fallback={<div style={{ margin: "50% auto auto auto", display: "table" }}><DotLoader color="#8f128e" loading /></div>}>
            <ReferAndEarn isSmallScreen={isSmallScreen} />
          </Suspense>
        )}
      </div>

      <div ref={refDevopsArchitect}>
        {inViewDevopsArchitect && (
          <Suspense>
            <DevopsArchitect isSmallScreen={isSmallScreen} heading={"DevopsCertification"}/>
          </Suspense>
        )}
      </div>

      <div ref={refIndustryTrend}>
        {inViewIndustryTrend && (
          <Suspense>
            <IndustryTrend isSmallScreen={isSmallScreen} />
          </Suspense>
        )}
      </div>

      <div ref={refNewsRoom}>
        {inViewNewsRoom && (
          <Suspense>
            <NewsRoom isSmallScreen={isSmallScreen} />
          </Suspense>
        )}
      </div>

      <div ref={refGoogleReviews}>
        {inViewGoogleReviews && (
          <Suspense>
            <GoogleReviews isSmallScreen={isSmallScreen} />
          </Suspense>
        )}
        </div>

        <div ref={refFAQs}>
          {inViewFAQs && (
            <Suspense>
              <FAQs isSmallScreen={isSmallScreen} heading={"DevopsCertification"}/>
            </Suspense>
          )}
        </div>

        <div ref={refGetInTouch}>
          {inViewGetInTouch && (
            <Suspense fallback={<div style={{ margin: "50% auto auto auto", display: "table" }}><DotLoader color="#8f128e" loading /></div>}>
              <GetInTouch isSmallScreen={isSmallScreen} />
            </Suspense>
          )}
        </div>
        <AllFooter />

        {showPopup &&
          <Suspense fallback={<div style={{ margin: "50% auto auto auto", display: "table" }}><DotLoader color="#8f128e" loading /></div>}>
            <ExitPagePopup setOpen={setShowPopup} open={showPopup} isSmallScreen={isSmallScreen} />
          </Suspense>
        }
    </React.Fragment>
  );
}
