import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Typography from '@mui/joy/Typography';
import { Avatar } from '@mui/joy';
import { Rating, Button } from '@mui/material';

export default function ReviewPopupCard(props) {
    const { fullText, name, rating, imageUrl, time, isSmallScreen } = props;

    const charLimit = isSmallScreen ? 100 : 140;
    const [isExpanded, setIsExpanded] = React.useState(false);

    return (
        <Card orientation="horizontal" variant="outlined" sx={{ width: isSmallScreen ? "88%" : 500, marginBottom: "1rem" }}>
            <CardOverflow>
                <Avatar sx={{ paddingLeft: "10px", height: isSmallScreen ? "30px" : "50px" , width: isSmallScreen ? "30px" : "50px"}} src={imageUrl} size="lg" />
            </CardOverflow>
            <CardContent>
                <Typography textColor="success.plainColor" sx={{ fontWeight: 'md' }}>
                    <div>
                        <div style={{ fontSize: isSmallScreen && "0.7rem" }}>{name}</div>
                        <div><Rating style={{ fontSize: isSmallScreen && "0.6rem" }} name="read-only" value={rating} readOnly size="small" />
                            <span style={{ fontSize: isSmallScreen ? "0.6rem" : "0.8rem", color: "grey", float: "right" }}>{time}</span>
                        </div>
                    </div>
                </Typography>
                <div>
                    <Typography level="body-sm" style={{ fontSize: isSmallScreen && "0.7rem" }}>
                        {isExpanded ? fullText : `${fullText.substring(0, charLimit)}...`}
                        {fullText.length > charLimit && (
                            <span style={{ fontSize: isSmallScreen ? "0.7rem" : "0.8rem", color: "blue", cursor: "pointer" }} size="small" onClick={() => setIsExpanded(!isExpanded)}>
                                {isExpanded ? "  See Less" : "  See More"}
                            </span>
                        )}
                    </Typography>
                </div>
            </CardContent>
            {/* <CardOverflow
                variant="soft"
                color="primary"
                sx={{
                    px: 0.2,
                    writingMode: 'vertical-rl',
                    justifyContent: 'center',
                    fontSize: 'xs',
                    fontWeight: 'xl',
                    letterSpacing: '1px',
                    textTransform: 'uppercase',
                    borderLeft: '1px solid',
                    borderColor: 'divider',
                }}
            >
                {time}
            </CardOverflow> */}
        </Card>
    );
}
