import React, { useState } from "react";
import './Footer.css';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { useMediaQuery, useTheme } from "@mui/material";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DropAQueryPopUp from "../PopUp/DropAQueryPopUp";

export default function StickyFooter() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [showPopup, setShowPopup] = useState(false);

    const handleClick = () => {
        setShowPopup(!showPopup);
    }

    return (
        <React.Fragment>
            <div className="sticky-footer" style={{fontSize: isSmallScreen && "0.6rem"}}>
                <div style={{display:"flex", paddingLeft:isSmallScreen ? "1%" : "10%", width: "33%", margin: "auto"}}>
                    <PhoneIcon style={{height: isSmallScreen && "1rem"}}/> <div><a class="footer-call-to-action-link" href="tel:+919430390172" target="_self">+91 9430390172</a></div>
                </div>

                <div style={{display:"flex", paddingLeft:isSmallScreen ? "1%" : "10%", width: "33%", margin: "auto", cursor: "pointer"}} onClick={handleClick}>
                <BorderColorIcon style={{height: isSmallScreen && "1rem"}}/> <div> Drop a query</div>
                </div>
                <div style={{display:"flex", paddingLeft:isSmallScreen ? "1%" : "10%", width: "33%", margin: "auto"}}>
                    <EmailIcon style={{height: isSmallScreen && "1rem"}}/> <div><a class="footer-email-to-action-link" href="mailto:support@paperlive.in" target="_self">support@paperlive.in</a></div>
                </div>
                {!isSmallScreen &&
                    <div style={{display:"flex", paddingLeft:isSmallScreen ? "1%" : "10%", width: "33%", margin: "auto"}}>
                    {/* <EmailIcon style={{height: isSmallScreen && "1rem"}}/> <div><a class="footer-email-to-action-link" href="mailto:support@paperlive.in" target="_self">support@paperlive.in</a></div> */}
                </div>
                }
                {showPopup &&
                    <DropAQueryPopUp setOpen={setShowPopup} open={showPopup} isSmallScreen={isSmallScreen} />
                }
            </div>
        </React.Fragment>
    );
}