import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ReviewPopupCard from './ReviewPopupCard';

export default function ReviewPopUp(props) {
    const { setOpen, open, isSmallScreen } = props;

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <ModalDialog
                aria-labelledby="nested-modal-title"
                aria-describedby="nested-modal-description"
            //     sx={(theme) => ({
            //         maxHeight: '80vh',  // Set a max height for scrolling
            //         overflowY: 'auto', // Enable vertical scrolling
            //         padding: '16px',
            //         [theme.breakpoints.only('xs')]: {
            //             top: 'unset',
            //             bottom: 0,
            //             left: 0,
            //             right: 0,
            //             borderRadius: 0,
            //             transform: 'none',
            //             maxWidth: 'unset',
            //             maxHeight: '70vh', // Adjust height for smaller screens
            //         },
            //     })
            // }
            >
                <Box sx={{ maxHeight: '60vh', overflowY: 'auto', padding: '10px' }}>
                    <ReviewPopupCard
                        name={"Sachin Kumar"}
                        fullText={"My decision to begin DevOps training with PaperLive Learning proved to be excellent. The course provided a great combination of helpful lessons and practical exercises that enhanced my skills and confidence in DevOps. The expert trainers made every class both interesting and comprehensible. PaperLive Learning excels not only for its superior content but also for the amazing support from its team. Highly recommended"}
                        imageUrl={"https://lh3.googleusercontent.com/a/ACg8ocLLJZ29s21T1mVx7eKTCayBx0ozI59D5rTALUbtmmMObJ94vg=s40-c-rp-mo-br100"}
                        rating={5}
                        time={"1 Hour ago"}
                        isSmallScreen={isSmallScreen}
                    />

                    <ReviewPopupCard
                        name={"Ayush Yadav"}
                        fullText={"Starting with PaperLive Learning for DevOps training was a great move. The course provided a perfect blend of informative lessons and practical exercises, which sharpened my skills and boosted my confidence. The expert instructors made each lesson engaging and straightforward. PaperLive Learning's unique appeal lies in its excellent course material and the exceptional support from its team. I highly recommend it"}
                        imageUrl={"https://lh3.googleusercontent.com/a-/ALV-UjVVfCo87trLkVxAjy1pfkwkSydVEL3hmxR0eLUhJ8Lz7pgJK6g9=s40-c-rp-mo-br100"}
                        rating={5}
                        time={"2 Hour ago"}
                        isSmallScreen={isSmallScreen}
                    />

                    <ReviewPopupCard
                        name={"Varsha Honavar"}
                        fullText={"A huge thank you to the superb trainer at PaperLive Learning! His teaching methods, combined with the excellent DevOps curriculum, made my job search a breeze."}
                        imageUrl={"https://lh3.googleusercontent.com/a-/ALV-UjUHTE8pqlE9fYnZ6eIeO3KNiMhTUjHQE4G1gLtKVLtB-5H3fmzG=s40-c-rp-mo-br100"}
                        rating={4}
                        time={"2 Hour ago"}
                        isSmallScreen={isSmallScreen}
                    />

                    <ReviewPopupCard
                        name={"Md Parwez Alam"}
                        fullText={"The DevOps course I took at PaperLive was amazing! I have absolutely no complaints. I was a bit apprehensive about making the decision, but I’m so glad I did—it turned out to be the best choice for me. I secured a job afterward, and I would definitely recommend this course to anyone! The trainer was extremely supportive, always ready to clear our queries without hesitation. If you’re looking to enhance your skills, PaperLive training is the way to go."}
                        imageUrl={"https://lh3.googleusercontent.com/a-/ALV-UjU-7pTvbpjEv0Zcgajvu_oOBfFeQy79RxNQICnQ6BG_WQx-WHGa=s40-c-rp-mo-br100"}
                        rating={5}
                        time={"4 Hour ago"}
                        isSmallScreen={isSmallScreen}
                    />

                    <ReviewPopupCard
                        name={"Saimi Ansari"}
                        fullText={"The DevOps course at PaperLive has been fantastic, especially since it helped me secure a great job. The course provided practical skills that are essential in the job market, and the teachers made the learning enjoyable. The staff was always there to support me, making the whole process enjoyable. A big thank you to everyone at PaperLive for their guidance in helping me start my career."}
                        imageUrl={"https://lh3.googleusercontent.com/a-/ALV-UjVEfY8EyrWdReWp7JpnvtT0prnYvgZ6FYUuO799SbnhWM3ln3ae=s40-c-rp-mo-br100"}
                        rating={5}
                        time={"5 Hour ago"}
                        isSmallScreen={isSmallScreen}
                    />

                    <ReviewPopupCard
                        name={"Shivnandan Kumar"}
                        fullText={"My choice to start DevOps training with PaperLive Learning was absolutely right. The course delivered a mix of valuable lessons and hands-on practice, which improved my skills and confidence in DevOps. The knowledgeable trainers made each class both engaging and easy to understand. PaperLive Learning stands out due to its exceptional content and the remarkable support from its staff. Highly recommended."}
                        imageUrl={"https://lh3.googleusercontent.com/a-/ALV-UjXZETudmffy2c5Z2f_WTfMKu42DfNfzzlsmLn0AJJZx9L89Ifwx=s40-c-rp-mo-br100"}
                        rating={5}
                        time={"5 Hour ago"}
                        isSmallScreen={isSmallScreen}
                    />

                    <ReviewPopupCard
                        name={"Keshav Kumar"}
                        fullText={"Enrolling in PaperLive Learning for my DevOps studies turned out to be a great decision. The course was full of useful lessons, and I had plenty of practice to refine my DevOps skills. The teachers were excellent, making the classes both fun and easy to follow. PaperLive Learning is amazing not just because of the course quality but also because the staff was incredibly helpful, always ready to assist, and they played a big part in my success."}
                        imageUrl={"https://lh3.googleusercontent.com/a-/ALV-UjUhlZuQk_ym0dUvMyNXLHa4LL0f3BMz7xY4itX28Td_GVXZJXuOfQ=s40-c-rp-mo-ba3-br100"}
                        rating={5}
                        time={"6 Hour ago"}
                        isSmallScreen={isSmallScreen}
                    />

                    <ReviewPopupCard
                        name={"Inderdew Prajapati"}
                        fullText={"The DevOps course at PaperLive was a game-changer for me. it helped me land a job, and the trainer's teaching style was out of this world!"}
                        imageUrl={"https://lh3.googleusercontent.com/a/ACg8ocKC9HWFMMkfCp6JQJuK02818uEkuXHH-hAW7ZCzU7nsHIDSaQ=s40-c-rp-mo-br100"}
                        rating={4}
                        time={"7 Hour ago"}
                        isSmallScreen={isSmallScreen}
                    />

                    <ReviewPopupCard
                        name={"Farzil Hai"}
                        fullText={"Choosing the DevOps Course from PaperLive was the best decision I could have made. Our trainer was incredibly supportive throughout the journey, guiding me at every step. He even arranged interviews and played a crucial role in helping me land the job. I’m very grateful to PaperLive for their assistance in getting me hired. I highly recommend PaperLive because they are genuinely invested in your success."}
                        imageUrl={"https://lh3.googleusercontent.com/a-/ALV-UjUNm5QDHkjmdvjntwFojn8iQ4Uu_7EGfhRf1mcOGRvzsHd4Zm3s=s40-c-rp-mo-br100"}
                        rating={5}
                        time={"9 Hour ago"}
                        isSmallScreen={isSmallScreen}
                    />

                    <ReviewPopupCard
                        name={"Kaushal Kumar Yadav"}
                        fullText={"Opting for PaperLive Learning to kickstart my DevOps learning was the best choice I could make. The course offered a mix of valuable lessons and hands-on practice, significantly improving my skills and confidence. The trainers, being experts, made each class engaging and easy to grasp. PaperLive Learning's strength lies not just in its quality content but also in its fantastic staff support. I highly recommend it"}
                        imageUrl={"https://lh3.googleusercontent.com/a-/ALV-UjUOr0UxpkqDw2fcSZyvHCrXei8plfVWXaODuBHutcEizVaosVc=s40-c-rp-mo-br100"}
                        rating={5}
                        time={"10 Hour ago"}
                        isSmallScreen={isSmallScreen}
                    />

                    <ReviewPopupCard
                        name={"Sai Cherry"}
                        fullText={"I was a fresher looking to enter the IT industry, and the DevOps and Multi-Cloud course at Paperlive Learning helped me gain the right skills. The trainer was patient, and the training quality was outstanding. The real-time projects gave me confidence to apply for jobs."}
                        imageUrl={"https://lh3.googleusercontent.com/a-/ALV-UjXUmGtU9nwxqfqMJsvs7rMb_1rJzos6yy5AX1yEloVPDBlG-DQ=s40-c-rp-mo-br100"}
                        rating={5}
                        time={"12 Hour ago"}
                        isSmallScreen={isSmallScreen}
                    />

                    <ReviewPopupCard
                        name={"Uma Shankar Bhagat"}
                        fullText={"The dedication of the PaperLive Learning staff to ensuring everyone’s success in the DevOps course was remarkable. They were always quick to respond, went above and beyond to help, and truly cared about how each student was progressing. Whenever questions arose, they provided fast and valuable answers that greatly enhanced the learning experience. The staff’s support created a great environment for learning and improvement. They remained committed to helping with any challenges, making the learning process smooth and enjoyable."}
                        imageUrl={"https://lh3.googleusercontent.com/a-/ALV-UjXFZLspb5VXbmYwxR20oO_pylDTUNRip7_3Pm3PBteXfqANUj6b=s40-c-rp-mo-br100"}
                        rating={5}
                        time={"13 Hour ago"}
                        isSmallScreen={isSmallScreen}
                    />

                    <ReviewPopupCard
                        name={"Arman Khan"}
                        fullText={"Thanks to my friend’s suggestion, I took the DevOps course at PaperLive, and it was the best decision I’ve made. The course directly led to my first job. The PaperLive team was amazing, providing all the necessary resources on time so we could finish our training without any issues. The trainer was very supportive, always ready to answer our questions. I’ll definitely be recommending this course to my friends!"}
                        imageUrl={"https://lh3.googleusercontent.com/a/ACg8ocI1A8JSvR9FQEG-x6jKHvuF-TVx9pTB0oGf3qHUOClRC8_Gqg=s40-c-rp-mo-br100"}
                        rating={5}
                        time={"13 Hour ago"}
                        isSmallScreen={isSmallScreen}
                    />

                    <ReviewPopupCard
                        name={"Prince Rajput"}
                        fullText={"I’m so happy with the DevOps course at PaperLive, which directly led to me securing a great job. The course provided me with practical DevOps skills, and the teachers made the learning process enjoyable. The staff was always there to support me, making the course experience delightful. Thanks to PaperLive, I’m starting my career on the right foot, and I’m deeply appreciative of all their help."}
                        imageUrl={"https://lh3.googleusercontent.com/a-/ALV-UjXUP-Z_MYXT7QMj6EL_BJldPghYF51FA91ED5BQxXbEH4RRKn1d=s40-c-rp-mo-br100"}
                        rating={5}
                        time={"16 Hour ago"}
                        isSmallScreen={isSmallScreen}
                    />

                    <ReviewPopupCard
                        name={"Mohammad Tausif"}
                        fullText={"I'm thrilled to share how the DevOps course at PaperLive had a huge impact on my career. The course offered excellent lessons, expert trainers, and outstanding support from the staff. The lessons were top-notch, teaching me practical skills and a deep understanding of DevOps. The trainers were experts who made learning enjoyable. The staff was always there to assist, making the entire course smooth and enjoyable. I'm incredibly grateful for this experience !"}
                        imageUrl={"https://lh3.googleusercontent.com/a-/ALV-UjVEYi8_Kv5u2fvbkbX-V1KGRQwHeJZYBDYaWoyZBc8yJ6hJQoo1=s40-c-rp-mo-br100"}
                        rating={5}
                        time={"21 Hour ago"}
                        isSmallScreen={isSmallScreen}
                    />

                    <ReviewPopupCard
                        name={"Sajan Kumar"}
                        fullText={"PaperLive Learning excels not only because of its top-tier course content but also due to the exceptional support from its team. They were always willing to help and were a significant factor in my success. Their swift answers and valuable guidance were immensely useful. I’m excited to share that this DevOps course was crucial for reaching my professional goals. The practical knowledge I gained was highly effective in real-world applications and contributed to landing my dream job"}
                        imageUrl={"https://lh3.googleusercontent.com/a/ACg8ocLLJZ29s21T1mVx7eKTCayBx0ozI59D5rTALUbtmmMObJ94vg=s40-c-rp-mo-br100"}
                        rating={5}
                        time={"21 Hour ago"}
                        isSmallScreen={isSmallScreen}
                    />

                    <ReviewPopupCard
                        name={"Sania Ashad"}
                        fullText={"I’m excited to say that this DevOps course was not just about academic learning; it was a significant step towards achieving my dream job. The practical insights I gained were useful in real-world applications and opened up new possibilities I hadn’t considered before. A big thank you to the PaperLive team for making this experience so exceptional!"}
                        imageUrl={"https://lh3.googleusercontent.com/a-/ALV-UjXB3Qo263yW2cQszvYOUH71Qk6LG1uVVQ0xPAhMjcg9Iu1jpM8q=s40-c-rp-mo-br100"}
                        rating={5}
                        time={"22 Hour ago"}
                        isSmallScreen={isSmallScreen}
                    />

                    <ReviewPopupCard
                        name={"SK Nazar"}
                        fullText={"I'm thrilled to share how the DevOps course at PaperLive had a huge impact on my career. The course offered excellent lessons, expert trainers, and outstanding support from the staff. The lessons were top-notch, teaching me practical skills and a deep understanding of DevOps. The trainers were experts who made learning enjoyable. The staff was always there to assist, making the entire course smooth and enjoyable. I'm incredibly grateful for this experience !"}
                        imageUrl={"https://lh3.googleusercontent.com/a-/ALV-UjVEYi8_Kv5u2fvbkbX-V1KGRQwHeJZYBDYaWoyZBc8yJ6hJQoo1=s40-c-rp-mo-br100"}
                        rating={5}
                        time={"23 Hour ago"}
                        isSmallScreen={isSmallScreen}
                    />

                    <ReviewPopupCard
                        name={"Pappu Kumar"}
                        fullText={"With the trainer’s fantastic support, PaperLive Learning’s DevOps course was a game-changer for me. It played a major role in getting my job. Highly recommended!"}
                        imageUrl={"https://lh3.googleusercontent.com/a-/ALV-UjUKTUN2FtS-5GHpwJlJKA7D2IjVema38jABziQcNn7iFnBQ96I6=s40-c-rp-mo-br100"}
                        rating={5}
                        time={"2 Days ago"}
                        isSmallScreen={isSmallScreen}
                    />

                    <ReviewPopupCard
                        name={"Omprakash Das"}
                        fullText={"The trainer was pivotal to my success! PaperLive Learning’s DevOps course, along with his excellent guidance, paved the way for my job. Highly recommend it!"}
                        imageUrl={"https://lh3.googleusercontent.com/a-/ALV-UjVdHjsWr09i3ost4DcoOKt00CV3rFHSAL5e8ZV4GNgjdpQsaYoA=s40-c-rp-mo-br100"}
                        rating={5}
                        time={"2 Days ago"}
                        isSmallScreen={isSmallScreen}
                    />

                </Box>
                <Box
                    sx={{
                        mt: 1,
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button variant="outlined" color="neutral" onClick={() => setOpen(false)}>
                        Close
                    </Button>
                </Box>
            </ModalDialog>
        </Modal>
    );
}
