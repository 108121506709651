import { Button, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import PaperLiveLogo from '../Header/paperlive-new-logo.webp';
import './ReviewHeader.css'

const ReviewHeader = (props) => {
  const { setOpen } = props;

  const handleReviewPopup = () => {
    setOpen(true);
  }

  const handleHomePage = () => {
    window.open(`/`, "_self");
  }

  return (
    <header className="header" style={{ backgroundColor: "black", padding: "1rem 10% 0 10%" }}>
      <img loading="lazy" src={PaperLiveLogo} alt="paperlive" className="logo" onClick={handleHomePage} style={{ cursor: "pointer", filter: "invert(1)" }} />
      <nav>
        <ul className="nav-links">
          {/* <li style={{ fontSize: "1.125rem", margin: "auto", fontWeight: 600 }}><a href="#referAndEarn">Invite and Earn</a></li> */}
          {/* <li style={{fontSize: "1.125rem"}}><a href="#free-masterclass">Free Masterclass</a></li> */}
          {/* <li><a href="#more">More</a></li> */}
        </ul>
      </nav>
      <div class="text-box">
        <a href="#" className="btn btn-white btn-animate" onClick={(e) => {
          e.preventDefault();
          handleReviewPopup();
        }}>
          See Reviews
        </a>
      </div>

    </header>
  );
}

export default ReviewHeader;
