import { Button, Card, TextField } from '@mui/material';
import * as React from 'react';

export default function DropAQueryReact(props) {
    const { isSmallScreen } = props;

    const [formError, setFormError] = React.useState('');
    const [formValues, setFormValues] = React.useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        website: window.location.href,
    });

    const [errors, setErrors] = React.useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
    });

    const validateFirstName = (value) => !value ? 'First name is required' : '';
    const validateLastName = (value) => !value ? 'Last name is required' : '';
    const validateEmail = (value) => {
        if (!value) return 'Email is required';
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value) ? '' : 'Invalid email address';
    };
    const validatePhone = (value) => {
        if (!value) return 'Phone number is required';
        const phoneRegex = /^\d{10}$/;
        return phoneRegex.test(value) ? '' : 'Phone number must be 10 digits';
    };

    const handleChange = (e, field) => {
        const { value } = e.target;
        setFormValues({ ...formValues, [field]: value });

        let error = '';
        switch (field) {
            case 'firstName': error = validateFirstName(value); break;
            case 'lastName': error = validateLastName(value); break;
            case 'email': error = validateEmail(value); break;
            case 'phone': error = validatePhone(value); break;
            default: break;
        }
        setErrors({ ...errors, [field]: error });
    };

    console.log("URLSearchParams", new URLSearchParams(window.location.search).get("gclid"));

    const handleFormSubmit = (event) => {
        event.preventDefault();

        document.getElementById("zc_gad").value = new URLSearchParams(window.location.search).get("gclid") || "";

        const firstNameError = validateFirstName(formValues.firstName);
        const lastNameError = validateLastName(formValues.lastName);
        const emailError = validateEmail(formValues.email);
        const phoneError = validatePhone(formValues.phone);

        if (firstNameError || lastNameError || emailError || phoneError) {
            setErrors({ firstName: firstNameError, lastName: lastNameError, email: emailError, phone: phoneError });
            setFormError(firstNameError || lastNameError || emailError || phoneError);
            return;
        }

        document.forms['WebToLeads774394000000902049'].submit();
    };

    return (
        <form id="webform774394000000902049" action="https://crm.zoho.in/crm/WebToLeadForm" name="WebToLeads774394000000902049" method="POST" onSubmit={handleFormSubmit} acceptCharset="UTF-8">
            <input type="hidden" name="xnQsjsdp" defaultValue="7d15d6ae01d5e4e362e4c5030d41265b910e3619da9c7a66ed4112cfbcdc414b" />
            {/* <input type="hidden" name="zc_gad" id="zc_gad" defaultValue="" /> */}
            <input type="hidden" name="xmIwtLD" defaultValue="44e1da7b6f7318fd661008fffb32f7aee45ebf52e977f8bcabba7a19c3c67dd9d51c23bfa87adc62bc6ea1224971de37" />
            <input type="hidden" name="actionType" defaultValue="TGVhZHM=" />
            <input type="hidden" name="returnURL" defaultValue="https://paperlive.in/thankyou" />
            <input type='hidden' id="zc_gad" name="zc_gad" value=""/>

            <TextField
                value={formValues.firstName}
                onChange={(e) => handleChange(e, "firstName")}
                id="First_Name"
                name="First Name"
                size="small"
                label="First Name"
                variant="outlined"
                style={{ width: "48%", marginBottom: "1rem" }}
                error={!!errors.firstName}
            // helperText={errors.firstName}
            />

            <TextField
                value={formValues.lastName}
                onChange={(e) => handleChange(e, "lastName")}
                id="Last_Name"
                name="Last Name"
                size="small"
                label="Last Name"
                variant="outlined"
                style={{ width: "48%", marginBottom: "1rem", float: "right" }}
                error={!!errors.lastName}
            // helperText={errors.lastName}
            />

            <TextField
                value={formValues.email}
                onChange={(e) => handleChange(e, "email")}
                id="Email"
                name="Email"
                size="small"
                label="Email"
                variant="outlined"
                style={{ width: "100%", marginBottom: "1rem" }}
                error={!!errors.email}
            // helperText={errors.email}
            />

            <TextField
                value={formValues.phone}
                onChange={(e) => handleChange(e, "phone")}
                id="Phone"
                name="Phone"
                size="small"
                label="Phone"
                variant="outlined"
                style={{ width: "100%", marginBottom: "1rem" }}
                error={!!errors.phone}
            // helperText={errors.phone}
            />
            <TextField
                value={formValues.website}
                id="Website"
                name="Website"
                size="small"
                label="Website"
                variant="outlined"
                style={{ width: "48%", marginBottom: "1rem", display: "none" }}
            />
            <div style={{ color: "red" }}>{formError}</div>
            <div style={{ width: "100%" }}>
                <Button
                    variant="contained"
                    style={{ color: "white", borderRadius: "25px", backgroundColor: "#8f128e", display: "block", margin: "auto" }}
                    type="submit"
                >
                    Submit
                </Button>
            </div>
        </form>
    );
}
