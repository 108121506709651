import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function HeaderDrawer() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <div style={{ margin: "3%" }}>
        <ArrowBackIcon onClick={(e) => setOpen(false)} />
      </div>
      <div style={{ width: "90%", margin: "auto auto 10px auto", border: "1px solid rgba(0,0,0,.1", borderRadius: "5px", padding: "3%" }}>
      <a href="/blog" style={{color: "black"}}>Blog</a>
      </div>
      <div style={{ width: "90%", margin: "auto auto 10px auto", border: "1px solid rgba(0,0,0,.1", borderRadius: "5px", padding: "3%" }}>
      <a href="#referAndEarn" style={{color: "black"}}>Invite and Earn</a>
      </div>
    </Box>
  );

  return (
    <div>
      <Button onClick={toggleDrawer(true)}>
        <MenuIcon style={{ color: "black" }} />
      </Button>
      <Drawer anchor='right' open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}