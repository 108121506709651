import { Breadcrumbs, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './ReviewSecondCom.css'

const ReviewSecondCom = (props) => {
    const { isSmallScreen, setOpen } = props;


    const handleReviewPopup = () => {
        setOpen(true);
    }


    return (
        <React.Fragment>
            <div style={{
                backgroundColor: "black",
                color: "white",
                textAlign: "center",
                paddingBottom: "2rem",
                // position: "relative",
                zIndex: 10
            }}>
                <a href="#" class="btn btn-white btn-animate"
                    onClick={(e) => {
                        e.preventDefault();
                        handleReviewPopup();
                    }}>
                    See Reviews
                </a>
            </div>

            <div style={{ backgroundColor: "black", color: "white", textAlign: "center", fontSize: isSmallScreen ? "1.5rem" : "3rem", fontWeight: "700" }}>
                Real Experiences from Real
                {isSmallScreen &&
                    <br />
                }
                <span class="blur-text"> </span>
                <span class="blur-text">L</span>
                <span class="blur-text">e</span>
                <span class="blur-text">a</span>
                <span class="blur-text">r</span>
                <span class="blur-text">n</span>
                <span class="blur-text">e</span>
                <span class="blur-text">r</span>
                <span class="blur-text">s</span>
            </div>
        </React.Fragment>
    );
}

export default ReviewSecondCom;
