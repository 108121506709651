import React, { useState } from 'react';
import { Button, Card, TextField } from '@mui/material';

export default function ExitPageReact(props) {
    const { isSmallScreen } = props;

    const [formError, setFormError] = useState('');
    const [formValues, setFormValues] = useState({
        fullName: '',
        email: '',
        phone: '',
        website: window.location.href,
    });

    const [errors, setErrors] = useState({
        fullName: '',
        email: '',
        phone: '',
    });

    const validateFullName = (value) => {
        if (!value) return 'Full name is required';
        return '';
    };

    const validateEmail = (value) => {
        if (!value) return 'Email is required';
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) return 'Invalid email address';
        return '';
    };

    const validatePhone = (value) => {
        if (!value) return 'Phone number is required';
        const phoneRegex = /^\d{10}$/;
        if (!phoneRegex.test(value)) return 'Phone number must be 10 digits';
        return '';
    };

    const handleChange = (e, field) => {
        const { value } = e.target;
        setFormValues({
            ...formValues,
            [field]: value,
        });

        let error = '';
        switch (field) {
            case 'fullName':
                error = validateFullName(value);
                break;
            case 'email':
                error = validateEmail(value);
                break;
            case 'phone':
                error = validatePhone(value);
                break;
            default:
                break;
        }

        setErrors({
            ...errors,
            [field]: error,
        });
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        const fullNameError = validateFullName(formValues.fullName);
        const emailError = validateEmail(formValues.email);
        const phoneError = validatePhone(formValues.phone);

        if (fullNameError || emailError || phoneError) {
            setErrors({
                fullName: fullNameError,
                email: emailError,
                phone: phoneError,
            });

            setFormError(fullNameError || emailError || phoneError);
            return;
        }

        // Submit form data if validation passes
        document.forms['WebToLeads774394000000444287'].submit();
    };

    return (
        // <Card>
        //     <div style={{
        //         margin: 'auto',
        //         padding: '0.5rem',
        //         fontSize: isSmallScreen ? '1.5rem' : '2rem',
        //         fontWeight: '600',
        //         color: 'white',
        //         height: isSmallScreen ? '40px' : '50px',
        //         backgroundImage: 'linear-gradient(90deg, #0075c9, #8f128e)'
        //     }}>
        //         Looking for discount code?
        //     </div>
        <div style={{ margin: '1rem' }}>
            <form
                id="webform774394000000444287"
                action="https://crm.zoho.in/crm/WebToLeadForm"
                name="WebToLeads774394000000444287"
                method="POST"
                onSubmit={handleFormSubmit}
                acceptCharset="UTF-8"
            >
                <input
                    type="text"
                    style={{ display: 'none' }}
                    name="xnQsjsdp"
                    defaultValue="ca4a19f81120744a5a803e4989578303ecc0f68ae79aeaae24cd23b5a93df99a"
                />
                {/* <input type="hidden" name="zc_gad" id="zc_gad" defaultValue="" /> */}
                <input
                    type="text"
                    style={{ display: 'none' }}
                    name="xmIwtLD"
                    defaultValue="f45712d1caab66d6fd3e1fd8d7ed4764ff7c6fcb232c742868bdc84b696c16f304f59ef36463834d39158f9477a90f02"
                />
                <input
                    type="text"
                    style={{ display: 'none' }}
                    name="actionType"
                    defaultValue="TGVhZHM="
                />
                <input
                    type="text"
                    style={{ display: 'none' }}
                    name="returnURL"
                    defaultValue="https://paperlive.in/thankyou"
                />
                <input type='hidden' id="zc_gad" name="zc_gad" value=""/>
                <TextField
                    value={formValues.fullName}
                    onChange={(e) => handleChange(e, 'fullName')}
                    id="Last_Name"
                    name="Last Name"
                    size="small"
                    label="Full Name"
                    variant="outlined"
                    style={{ width: '100%', marginBottom: '1rem' }}
                    error={!!errors.fullName}
                // helperText={errors.fullName}
                />
                <TextField
                    value={formValues.email}
                    onChange={(e) => handleChange(e, 'email')}
                    id="Email"
                    name="Email"
                    size="small"
                    label="Email"
                    variant="outlined"
                    style={{ width: '100%', marginBottom: '1rem' }}
                    error={!!errors.email}
                // helperText={errors.email}
                />
                <TextField
                    value={formValues.phone}
                    onChange={(e) => handleChange(e, 'phone')}
                    id="Phone"
                    name="Phone"
                    size="small"
                    label="Phone"
                    variant="outlined"
                    style={{ width: '100%', marginBottom: '1rem' }}
                    error={!!errors.phone}
                // helperText={errors.phone}
                />
                <TextField
                    value={formValues.website}
                    id="Website"
                    name="Website"
                    size="small"
                    label="Website"
                    variant="outlined"
                    style={{ width: "48%", marginBottom: "1rem", display: "none" }}
                />
                <div style={{ color: 'Red' }}>{formError}</div>
                <div style={{ width: '100%' }}>
                    <Button
                        variant="contained"
                        style={{
                            color: 'white',
                            borderRadius: '25px',
                            backgroundColor: '#8f128e',
                            display: 'block',
                            margin: 'auto'
                        }}
                        type="submit"
                    >
                        Submit
                    </Button>
                </div>
            </form>
        </div>
        // </Card>
    );
}
