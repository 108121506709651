import React from "react";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import './DynamicVideo.css'

export const DynamicVideo = (props) => {
  const { isSmallScreen } = props;

  return (
    <div
      className={isSmallScreen ? "video-container-mb" : "video-container"}
      aria-label="Trusted by Learners, Backed by Results"
    >
       <DotLottieReact
        src={`${process.env.PUBLIC_URL}/lottie/animation.json`}
        loop
        autoplay
        rendererSettings={{
          preserveAspectRatio: "xMidYMid slice",
        }}
        style={{
          width: isSmallScreen ? "100%" : "150%",
          height: isSmallScreen ? "100%" : "120%",
          transform: isSmallScreen ? "scale(1.5)" : "scale(1.0)",
          zIndex: 1,
        }}
      />

      {/* Fallback for non-JavaScript users */}
      <noscript>
        <div className="fallback-text">
          <h2>Trusted by Learners</h2>
          <h3>Backed by Results</h3>
        </div>
      </noscript>

      <div className= {isSmallScreen ? "overlay-text-mb" : "overlay-text"}>
        Trusted by Learners
        <br />
        Backed by Results
      </div>
    </div>
  );
};
