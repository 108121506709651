import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ExitImage from '../components/images/ExitImage.webp';
import CloseIcon from '@mui/icons-material/Close';
import ExitPage from '../HTML/ExitPage';
import ExitPageReact from '../HTML/ExitPageReact';

export default function ExitPagePopup(props) {
    const { open, setOpen, isSmallScreen, refer } = props;
    const handleClose = () => setOpen(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isSmallScreen ? "70%" : "50%",
        height: isSmallScreen ? "430px" : "430px",
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: "20px"
    };

    const [formError, setFormError] = React.useState('');

    const [formValues, setFormValues] = React.useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
    });

    const [errors, setErrors] = React.useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
    });

    const validateFirstName = (value) => {
        if (!value) return 'First name is required';
        return '';
    };

    const validateLastName = (value) => {
        if (!value) return 'Last name is required';
        return '';
    };

    const validateEmail = (value) => {
        if (!value) return 'Email is required';
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) return 'Invalid email address';
        return '';
    };

    const validatePhone = (value) => {
        if (!value) return 'Phone number is required';
        const phoneRegex = /^\d{10}$/;
        if (!phoneRegex.test(value)) return 'Phone number must be 10 digits';
        return '';
    };

    const handleChange = (e, event) => {
        const { value } = e.target;
        setFormValues({
            ...formValues,
            [event]: value,
        });

        let error = '';
        switch (event) {
            case 'firstName':
                error = validateFirstName(value);
                break;
            case 'lastName':
                error = validateLastName(value);
                break;
            case 'email':
                error = validateEmail(value);
                break;
            case 'phone':
                error = validatePhone(value);
                break;
            default:
                break;
        }

        setErrors({
            ...errors,
            [event]: error,
        });
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        const firstNameError = validateFirstName(formValues.firstName);
        const lastNameError = validateLastName(formValues.lastName);
        const emailError = validateEmail(formValues.email);
        const phoneError = validatePhone(formValues.phone);
        if (firstNameError || lastNameError || emailError || phoneError) {
            setErrors({
                firstName: firstNameError,
                lastName: lastNameError,
                email: emailError,
                phone: phoneError,
            });

            if (firstNameError) {
                setFormError(firstNameError);
            } else if (lastNameError) {
                setFormError(lastNameError);
            } else if (emailError) {
                setFormError(emailError);
            } else if (phoneError) {
                setFormError(phoneError);
            }

            return;
        }
        else {
            document.forms['WebToLeads774394000000340007'].submit();
        }
    };

    return (
        <div>
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <div style={{ display: "flex" }}>
                        {!isSmallScreen &&
                            <div style={{ width: "50%" }}>
                                <img loading="lazy" width={"430px"} height={"430px"} src={ExitImage} style={{ maxWidth: "100%", maxHeight: "100%", borderRadius: "20px" }} />
                            </div>
                        }
                        <div style={{ width: isSmallScreen ? "100%" : "50%" }}>
                            <div style={{ height: "2rem", padding: "0.2rem", cursor: "pointer" }} onClick={handleClose}><CloseIcon style={{ float: "right" }} /></div>
                            {
                                    <div style={{ textAlign: "center", fontSize: isSmallScreen ? "1.1rem" : "1.5rem", fontWeight: "600" }}>Hello Aspirants!</div>
                            }
                            <div style={{ margin: isSmallScreen ? "1rem" : "2rem 1rem 1rem 1rem" }}>
                                <ExitPageReact />
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}