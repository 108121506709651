import { Breadcrumbs, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './ReviewMainPage.css'
import ReviewHeader from './ReviewHeader';
import ReviewCardMain from './ReviewCardMain';
import { DynamicVideo } from './DynamicVideo';
import ReviewSecondCom from './ReviewSecondCom';
import ReviewPopUp from './ReviewPopUp';

const ReviewMainPage = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <div style={{ backgroundColor: "black", minHeight: "100vh", color: "white" }}>
                <ReviewHeader setOpen={setOpen}/>
                <DynamicVideo isSmallScreen={isSmallScreen}/>
                <ReviewSecondCom setOpen={setOpen} isSmallScreen={isSmallScreen}/>
                {open &&
                    <ReviewPopUp setOpen={setOpen} open={open} isSmallScreen={isSmallScreen}/>
                }
            </div>
        </React.Fragment>
    );
}

export default ReviewMainPage;
